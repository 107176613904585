import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Match Fishtank" />
    <div className="container mx-auto w-full py-8">Home</div>
  </Layout>
)

export default IndexPage
